import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Modal, Share, TextInput } from 'react-native';
import { useState, useEffect } from 'react';
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { Svg, Circle, Line } from 'react-native-svg';

//import * as Sentry from "@sentry/react-native";

// Sentry.init({
//   dsn: "https://1b97dec8dae04999a970aac9272bb3dd@o1184095.ingest.sentry.io/4505386421256192",
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production.
//   tracesSampleRate: 1.0,
// });

const firebaseConfig = {
  apiKey: 'AIzaSyBz26bO3hb8eQVBWVX8Lj7Q0whcNfHj4OE',
  projectId: 'murphle',
  //messagingSenderId: 'sender-id',
  appId: 'murphle',
  measurementId: 'G-K04R9R9SW4',
};

const getPR = async () => {
  try {
    const value = await AsyncStorage.getItem('@pr');
    if (value !== null) {
      // value previously stored
      console.log("PR", value);
    }
  } catch (e) {
    // error reading value
  }
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  const pushupIcon = '🙌'; //'🫸';
  const pullupIcon = '✊';
  const squatIcon = '🍑';
  const runIcon = '🏃';
  const watchIcon = '⌚';
  const g = '🟩';
  const y = '🟨';
  const b = '⬛';

  const [pushups, setPushups] = useState(0);
  const [pullups, setPullups] = useState(0);
  const [squats, setSquats] = useState(0);
  const [run1, setRun1] = useState(0.0);
  const [run2, setRun2] = useState(0.0);

  const [copiedText, setCopiedText] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState();
  const [editingField, setEditingField] = useState();

  const [watchStart, setWatchStart] = useState();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: formatSharedContent()
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => {
        console.log((new Date().getTime() - watchStart));
        setTime((new Date().getTime() - watchStart))//setTime(time + 100)
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Hours calculation
  const hours = Math.floor(time / 3600000);

  // Minutes calculation
  const minutes = Math.floor((time % 3600000) / 60000);

  // Seconds calculation
  const seconds = Math.floor((time % 60000) / 1000);

  // Method to start and stop timer
  const startAndStop = () => {
    if (!isRunning) {
      setWatchStart(new Date().getTime());
    }

    setIsRunning(!isRunning);
  };

  function addPushup() {
    setPushups(pushups + 1);
  }

  function addPullup() {
    setPullups(pullups + 1);
  }

  function addSquat() {
    setSquats(squats + 1);
  }

  function addRun1() {
    setRun1(run1 + 0.1);
  }

  function addRun2() {
    setRun2(run2 + 0.1);
  }

  function formatString(value, target) {
    var pullupString = '';

    if (value >= target / 5) {
      pullupString += g;
    }
    if (value >= 2 * target / 5) {
      pullupString += g;
    }
    if (value >= 3 * target / 5) {
      pullupString += g;
    }
    if (value >= 4 * target / 5) {
      pullupString += g;
    }
    if (value >= target) {
      pullupString += g
    }

    if (value % (target / 5) > 0) {
      pullupString += y;
    }

    pullupString += b.repeat(5 - pullupString.length / 2);

    return pullupString;
  }

  function formatSharedContent() {
    const today = new Intl.DateTimeFormat('sv-SE', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(new Date());

    const run1String = formatString(Math.round(run1 * 100), 100);
    const pullupString = formatString(pullups, 100);
    const pushupString = formatString(pushups, 200);
    const squatString = formatString(squats, 300);
    const run2String = formatString(Math.round(run2 * 100), 100);

    var numberFormatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    });

    var results = "💪 Murphle 🏃 " + today + "\n\n" +
      runIcon + run1String + ' ' + numberFormatter.format(run1) + '\n' +
      pullupIcon + pullupString + ' ' + pullups + '\n' +
      pushupIcon + pushupString + ' ' + pushups + '\n' +
      squatIcon + squatString + ' ' + squats + '\n' +
      runIcon + run2String + ' ' + numberFormatter.format(run2) + '\n' +
      `\n${watchIcon}${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}\n` +
      "\nmurphle.com";

    return results;
  };

  async function copyToClipboard() {
    const results = formatSharedContent();

    await Clipboard.setStringAsync(results);

    setCopiedText(results);
    //setModalVisible(true);
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>💪 Murphle 🏃</Text>

      {/* <View>
        <Svg height="20" width="20">
          <Circle cx="10" cy="10" r="10" fill="blue" />
          <Line x1="4" y1="4" x2="16" y2="16" stroke="#fff" strokeWidth="2" />
          <Line x1="4" y1="16" x2="16" y2="4" stroke="#fff" strokeWidth="2" />
        </Svg>
      </View> */}

      <View style={[styles.row, styles.bottomMargin]}>
        <Text style={styles.timer}>
          {hours}:{minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </Text>
        <View styles={styles.buttonContainer}>
          <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name={isRunning ? 'stop' : 'play-arrow'} onPress={startAndStop}></MaterialIcons.Button>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>RUN 1</Text>
        <Text style={styles.count} onPress={() => { setEditingField('run1'); setModalText(run1.toFixed(1)); setModalVisible(true); }}>{new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).format(run1)} mi</Text>


        <View style={styles.buttonRow}>
          <View styles={styles.buttonContainer}>
            <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="add" onPress={addRun1}></MaterialIcons.Button>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>PULL-UPS</Text>
        <Text onPress={() => { setEditingField('pullups'); setModalText(pullups); setModalVisible(true); }} style={styles.count}>{pullups}/100</Text>

        <View style={styles.buttonRow}>
          <View styles={styles.buttonContainer}>
            <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="add" onPress={addPullup}></MaterialIcons.Button>
          </View>
          <View styles={styles.buttonContainer}>
            <MaterialCommunityIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="fast-forward-5" onPress={() => setPullups(pullups + 5)}></MaterialCommunityIcons.Button>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>PUSH-UPS</Text>
        <Text onPress={() => { setEditingField('pushups'); setModalText(pushups); setModalVisible(true); }} style={styles.count}>{pushups}/200</Text>

        <View style={styles.buttonRow}>
          <View styles={styles.buttonContainer}>
            <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="add" onPress={addPushup}></MaterialIcons.Button>
          </View>
          <View styles={styles.buttonContainer}>
            <MaterialCommunityIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="fast-forward-5" onPress={() => setPushups(pushups + 5)}></MaterialCommunityIcons.Button>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>SQUATS</Text>
        <Text onPress={() => { setEditingField('squats'); setModalText(squats); setModalVisible(true); }} style={styles.count}>{squats}/300</Text>

        <View style={styles.buttonRow}>
          <View styles={styles.buttonContainer}>
            <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="add" onPress={addSquat}></MaterialIcons.Button>
          </View>
          <View styles={styles.buttonContainer}>
            <MaterialCommunityIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="fast-forward-5" onPress={() => setSquats(squats + 5)}></MaterialCommunityIcons.Button>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>RUN 2</Text>
        <Text style={styles.count} onPress={() => { setEditingField('run2'); setModalText(run2.toFixed(1)); setModalVisible(true); }}>{new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).format(run2)} mi</Text>

        <View style={styles.buttonRow}>
          <View styles={styles.buttonContainer}>
            <MaterialIcons.Button style={styles.addButton} iconStyle={{ margin: 5 }} name="add" onPress={addRun2}></MaterialIcons.Button>
          </View>
        </View>
      </View>

      <View style={styles.shareButtons}>
        <MaterialIcons.Button name="share" onPress={onShare}>Share</MaterialIcons.Button>
      </View>

      <View styles={styles.centeredView}>
        <Modal
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <TextInput style={styles.modalInput} onChangeText={setModalText} value={modalText?.toString()} />
              <View style={[styles.buttonClose]}>
                <MaterialIcons name="close" size={18} onPress={() => {
                  switch (editingField) {
                    case 'run1':
                      setRun1(parseFloat(modalText));
                      break;
                    case 'pullups':
                      setPullups(parseInt(modalText));
                      break;
                    case 'pushups':
                      setPushups(parseInt(modalText));
                      break;
                    case 'squats':
                      setSquats(parseInt(modalText));
                      break;
                    case 'run2':
                      setRun2(parseFloat(modalText));
                      break;
                  }
                  setModalVisible(false);
                }} />
              </View>
            </View>
          </View>
        </Modal>
      </View >
      <StatusBar style="auto" />
    </View >
  );
}

//export default Sentry.wrap(App);
export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 32,
    marginBottom: 75
  },
  bottomMargin: {
    marginBottom: 25
  },
  timer: {
    fontSize: 36,
    width: 240,
    textAlign: 'center'
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    fontSize: 18,
    margin: 10,
    width: 100
  },
  count: {
    fontSize: 24,
    margin: 10,
    width: 100
  },
  buttonContainer: {
    height: 10
  },
  addButton: {
    padding: 4,
    width: 40,
    height: 40
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  buttonRow: {
    flexDirection: 'row',
    width: 85,
    justifyContent: 'space-between'
  },
  shareButtons: {
    flexDirection: 'row',
    marginTop: 50,
    width: 100,
    justifyContent: 'space-between'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  modalInput: {
    width: 75,
    fontSize: 24,
    borderWidth: 1,
    padding: 2
  }
});
